.form-control {
    font-size: 1rem;
    color: #4b5563;    
}
.p-dropdown .p-dropdown-label.p-placeholder {
    font-size: 1rem;
    color: #4b5563;
}
.p-dropdown .p-dropdown-label.p-inputtext {
    font-size: 1rem;
    color: #4b5563;
}
.p-dropdown-items{
    padding: 0;
}
.p-dropdown-item > .align-items-center{
    justify-content: left !important;
}
.button-fod {
    width: 2.3rem;
    height: 2.3rem;
}

.button-fod .pi {
    font-size: .9rem;
}

.button-color-fod-blue {
    color: #ffffff;
    background: #002a5c;
    border: 1px solid #002a5c;
}

.p-button {
    border-radius: 6px !important;
}
.button-color-fod-blue:hover {
    color: #ffffff;
}

.text-color-fod-green {
    color: #7ac142;
}

.btn-fod-green {
    color: #ffffff;
    background: #7ac142;
    border: 1px solid #7ac142;
}

.btn-fod-green:focus {
    box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #7ac142, 0 1px 2px 0 black;
}

.size-icons {
    width: 20px;
    height: 20px;
}

.custom-icon-prod {
    background-image: url('./layout/images/icons/tractor-blue.png');
}

.custom-icon-sat {
    background-image: url('./layout/images/icons/satellite.png');
}

.custom-icon-time {
    background-image: url('./layout/images/icons/timeline.png');
}

.custom-icon-bk {
    background-image: url('./layout/images/icons/twoblocks.png');
}

.custom-icon-box {
    filter: invert(14%) sepia(54%) saturate(2176%) hue-rotate(193deg) brightness(92%) contrast(219%);
    background-size: 100%;
    background-repeat: no-repeat;
    background-image: url('./layout/images/icons/archives.png');
}

.custom-icon-fruit {
    background-image: url('./layout/images/icons/fruits-pear.png');
}

.custom-icon-meat {
    background-image: url('./layout/images/icons/meat.png');
}

.custom-icon-grains {
    background-image: url('./layout/images/icons/grains.png');
}

.custom-icon-fish {
    background-image: url('./layout/images/icons/seafood.png');
}

.custom-icon-map {
    background-image: url('./layout/images/icons/maps.png');
}

.custom-icon-home {
    background-image: url('./layout/images/icons/home.png');
}

.custom-icon-users {
    background-image: url('./layout/images/icons/users1.png');
}

.custom-icon-useri {
    filter: invert(14%) sepia(54%) saturate(2176%) hue-rotate(193deg) brightness(92%) contrast(219%);
    background-size: 100%;
    background-repeat: no-repeat;
    background-image: url('./layout/images/icons/users.png');
}

.custom-icon-bouthservice {
    filter: invert(14%) sepia(54%) saturate(2176%) hue-rotate(193deg) brightness(92%) contrast(219%);
    background-size: 100%;
    background-repeat: no-repeat;
    background-image: url('./layout/images/icons/cart.png');
}
.custom-icon-buyservice {
    filter: invert(14%) sepia(54%) saturate(2176%) hue-rotate(193deg) brightness(92%) contrast(219%);
    background-size: 100%;
    background-repeat: no-repeat;
    background-image: url('./layout/images/icons/responsible.png');
}
.custom-icon-adduser {
    filter: invert(14%) sepia(54%) saturate(2176%) hue-rotate(193deg) brightness(92%) contrast(219%);
    background-size: 100%;
    background-repeat: no-repeat;
    background-image: url('./layout/images/icons/add-user.png');
}
.custom-icon-userautorization {
    filter: invert(14%) sepia(54%) saturate(2176%) hue-rotate(193deg) brightness(92%) contrast(219%);
    background-size: 100%;
    background-repeat: no-repeat;
    background-image: url('./layout/images/icons/keyuser.png');
}

.custom-icon-wallet {
    filter: invert(14%) sepia(54%) saturate(2176%) hue-rotate(193deg) brightness(92%) contrast(219%);
    background-size: 100%;
    background-repeat: no-repeat;
    background-image: url('./layout/images/icons/wallet.png');
}

.custom-icon-task {
    background-image: url('./layout/images/icons/task.png');
}

.custom-icon-manual {
    filter: invert(14%) sepia(54%) saturate(2176%) hue-rotate(193deg) brightness(92%) contrast(219%);
    background-size: 100%;
    background-repeat: no-repeat;
    background-image: url('./layout/images/icons/readme.png');
}

.custom-icon-support {
    background-image: url('./layout/images/icons/support.png');
}

.layout-sidebar {
    left: 0;
}
.p-card-content {
    padding: 0;
}
.layout-main-container {
    background-image: url('./layout/images/bg-gray.png');
    background-repeat: no-repeat;
    /*background-size: cover;*/
    margin-top: 40px;
    padding: 3.4rem 1rem 2rem 1rem;
}

.card-table {
    margin-top: 15px;
}

.p-card-header {
    text-align: center;
    padding: 10px;
}

.p-card-title {
    text-align: center;
}

.hoverClass .p-tooltip-text {
    color: #002a5c;
    font-weight: 500;
    background-color: rgba(122, 193, 66, .8) !important;
}

.p-datepicker-trigger {
    height: 35px;
    color: #ffffff;
    background: #002a5c;
    border: 1px solid #002a5c;
}

.p-datepicker-trigger:hover {
    color: #ffffff;
    background: #002a5c;
    border: 1px solid #002a5c;
}

.p-calendar {
    height: 35px;
}

label {
    color: #002a5c;
}

.p-inputnumber-button-up, .p-inputnumber-button-down {
    background-color: #7ac142;
    border-color: #7ac142;
}

.p-card {
    box-shadow: 0 0px 0px -1px rgba(0, 0, 0, 0.2), 0 0px 0px 0 rgba(0, 0, 0, 0.14), 0 0px 0px 0 rgba(0, 0, 0, 0.12);
}

.p-card-title {
    font-size: 1.2rem;
    color: #002a5c;
}

.timeline-title {
    font-size: 1.2rem;
    color: #002a5c;
    font-weight: 600;
}

.bg {
    background-image: url('./layout/images/bgtree1.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.p-timeline .p-timeline-event-connector {
    background-color: #2D5980;
}

.separator {
    position: relative;
    width: var(--width);
    height: var(--height);
  }
  
  .separator::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background-color: var(--top-color);
    clip-path: polygon(100% 0, 0 0, 0 100%);
  }
  
  .separator::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background-color: var(--bottom-color);
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
  }
  
  .separator.reverse {
    transform: rotateY(180deg);
  }
  
  .vertical .separator.reverse {
    transform: rotateX(180deg);
  }

  .div-image-tab {
    position: absolute;
    top: 0px;
    left: 4px;
  }

  .flecha {
    float: left;
    position: relative;
    height: 80px;
    width: 150px;
    border: solid 1px #ccc;
    border-right: none;
    background: #eee linear-gradient(white, #f1f1f1 37%, #e1e1e1 57%, #f6f6f6);
  }
  
  .flecha:after {
    position: absolute;
    top: 16px;
    right: -25px;
    width: 48px;
    height: 47px;
    border-left: solid 1px #000;
    border-top: solid 1px #000;
    transform: rotate(134deg) skewX(-10deg) skewY(-10deg);
    background: #eee linear-gradient(45deg, white, #f1f1f1 37%, #e1e1e1 57%, #f6f6f6);
    content: '';
  }

  .p-overlaypanel .p-overlaypanel-close {
    background: #002a5c;
  }


.main-timeline10:after,.main-timeline10:before{content:"";display:block;width:100%;clear:both}
.main-timeline10 .timeline{padding:0;display:-webkit-inline-box}
/*.main-timeline10 .col-md-3{-ms-flex:0 0 25%;flex:0 0 25%;max-width:24%}*/
.main-timeline10 .timeline-inner{text-align:center;margin:10px 20px 35px 35px;position:relative; min-width: 125px; min-height: 125px;}
.main-timeline10 .timeline-inner:after{content:"";width:120%;height:2px;background:#2d5980;position:absolute;bottom:0;left:10%}
.main-timeline10 .timeline:last-child .timeline-inner:after{width:0}
.main-timeline10 .year{background:#7ac142;padding:5px 0;border-radius:30px 0;font-size:26px;font-weight:700;color:#fff;z-index:1;position:relative}
.main-timeline10 .year:after,.main-timeline10 .year:before{position:absolute;top:-19px;content:""}
.main-timeline10 .year:before{right:0;border:10px solid transparent;border-bottom:10px solid #7ac142;border-right:10px solid #7ac142}
.main-timeline10 .year:after{width:25px;height:19px;border-radius:0 0 20px;background:#fff;right:1px}
.main-timeline10 .timeline-content{padding:30px 10px 30px;border-left:0px solid #7ac142;position:relative;margin-left: -30px;}
.main-timeline10 .timeline-content:before{content:"";position:absolute;top:0;left:-1px;border:10px solid transparent;border-top:0px solid #7ac142;border-left:0px solid #7ac142}
.main-timeline10 .timeline-content:after{content:"";width:25px;height:19px;border-radius:20px 0 0;background:#fff;position:absolute;top:0;left:0}
.main-timeline10 .post{font-size:1.2rem;color:#333}
.main-timeline10 .description{font-size:14px;color:#333}
.main-timeline10 .timeline-icon{width:45px;height:45px;line-height:40px;border-radius:50%;border:5px solid #7ac142;background:#fff;font-size:30px;color:#555;z-index:1;position:absolute;bottom:-15px;left:-20px}
.main-timeline10 .timeline:nth-child(2n) .year{background:#7ac142}
.main-timeline10 .timeline:nth-child(2n) .year:before{border-bottom-color:#7ac142;border-right-color:#7ac142}
.main-timeline10 .timeline:nth-child(2n) .timeline-content{border-left-color:#7ac142}
.main-timeline10 .timeline:nth-child(2n) .timeline-content:before{border-top-color:#7ac142;border-left-color:#7ac142}
.main-timeline10 .timeline:nth-child(2n) .timeline-icon{border-color:#7ac142}
.main-timeline10 .timeline:nth-child(3n) .year{background:#7ac142}
.main-timeline10 .timeline:nth-child(3n) .year:before{border-bottom-color:#7ac142;border-right-color:#7ac142}
.main-timeline10 .timeline:nth-child(3n) .timeline-content{border-left-color:#7ac142}
.main-timeline10 .timeline:nth-child(3n) .timeline-content:before{border-top-color:#7ac142;border-left-color:#7ac142}
.main-timeline10 .timeline:nth-child(3n) .timeline-icon{border-color:#7ac142}
.main-timeline10 .timeline:nth-child(4n) .year{background:#7ac142}
.main-timeline10 .timeline:nth-child(4n) .year:before{border-bottom-color:#7ac142;border-right-color:#7ac142}
.main-timeline10 .timeline:nth-child(4n) .timeline-content{border-left-color:#7ac142}
.main-timeline10 .timeline:nth-child(4n) .timeline-content:before{border-top-color:#7ac142;border-left-color:#7ac142}
.main-timeline10 .timeline:nth-child(4n) .timeline-icon{border-color:#7ac142}
@media only screen and (max-width:990px){.main-timeline10 .timeline-inner:after{width:110%}
.main-timeline10 .timeline:nth-child(2n) .timeline-inner:after{width:0}
}
@media only screen and (max-width:767px){.main-timeline10 .timeline{margin-bottom:50px}
.main-timeline10 .timeline-inner:after,.main-timeline10 .timeline:nth-child(2n) .timeline-inner:after{width:100%;height:3px;left:0}
}

.p-sidebar-header {
    padding: 5px 15px;
    background-color: #f8f9fe;
}  

.p-card {
    border: 1px solid #ccc;
}

.p-accordion .p-accordion-header .p-accordion-header-link {
    /*padding: 1.25rem;*/
    padding: 0px;
    padding-left: 5px;
    border: 1px solid #ccc;
    background: #f8f9fe;
  }