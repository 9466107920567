/**
 * Main wrapper
 */
.select-search-container {
    width: auto;
    position: relative;
    font-family: 'Nunito Sans', sans-serif;
    box-sizing: border-box;
}

.select-search-container *,
.select-search-container *::after,
.select-search-container *::before {
    box-sizing: inherit;
}

/**
 * Value wrapper
 */
.select-search-value {
    position: relative;
    z-index: 1;
}

.select-search-value::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: calc(50% - 9px);
    right: 19px;
    width: 7px;
    height: 7px;
}

/**
 * Input
 */
.select-search-input {
    /*color: #8898aa;
    font-size: 12px;
    */
    font-size: 1rem;
    color: #4b5563;
    display: block;
    height: 42px;
    width: 100%;
    padding: 0 16px;
    background: #fff;
    border: 1px solid transparent;
    box-shadow: 0 .0625rem .125rem rgba(0, 0, 0, 0.15);
    border-radius: 3px;
    outline: none;
    font-family: 'Noto Sans', sans-serif;
    text-align: left;
    text-overflow: ellipsis;
    line-height: 36px;
    -webkit-appearance: none;
}

.select-search-input::-webkit-search-decoration,
.select-search-input::-webkit-search-cancel-button,
.select-search-input::-webkit-search-results-button,
.select-search-input::-webkit-search-results-decoration {
    -webkit-appearance:none;
}

.select-search-input:not([readonly]):focus {
    cursor: initial;
}

/**
 * Options wrapper
 */
.select-search-select {
    display: none;
    background: #fff;
    box-shadow: 0 .0625rem .125rem rgba(0, 0, 0, 0.15);
}
.select-search-has-focus .select-search-select {
    border-color: transparent;
}
/**
 * Options
 */
.select-search-options {
    list-style: none;
}

/**
 * Option row
 */
.select-search-row:not(:first-child) {
    border-top: 1px solid #eee;
}

/**
 * Option
 */
.select-search-option {
    height: auto;
}
.select-search-option,
.select-search-not-found {
    /*
    display: block;
    height: 36px;
    cursor: pointer;
    outline: none;
    font-family: 'Noto Sans', sans-serif;
    padding: 0 16px;
    */
    color: #8898aa;
    width: 100%;
    background: #fff;
    font-size: 12px;
    border: none;
    text-align: left;
}

.select-search--multiple .select-search-option {
    height: 48px;
}

.select-search-option.select-search-is-selected {
    background: #2FCC8B;
    color: #fff;
}

.select-search-option.select-search-is-highlighted,
.select-search-option:not(.select-search-is-selected):hover {
    background: rgba(47, 204, 139, 0.1);
}

.select-search-option.select-search-is-highlighted.select-search-is-selected,
.select-search-option.select-search-is-selected:hover {
    background: #2eb378;
    color: #fff;
}

/**
 * Group
 */
.select-search-group-header {
    font-size: 10px;
    text-transform: uppercase;
    background: #eee;
    padding: 8px 16px;
}

/**
 * States
 */
.select-search.select-search-is-disabled {
    opacity: 0.5;
}

/*.select-search.select-search-is-loading .select-search-value::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cpath fill='%232F2D37' d='M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
    background-size: 11px;
}*/

.select-search-container:not(.select-search-is-disabled) .select-search-input {
    cursor: pointer;
}

/**
 * Modifiers
 */
.select-search--multiple {
    border-radius: 3px;
    overflow: hidden;
}

.select-search-container:not(.select-search-is-loading):not(.select-search--multiple) .select-search-value::after {
    transform: rotate(45deg);
    border-right: 1px solid #000;
    border-bottom: 1px solid #000;
    pointer-events: none;
}

.select-search--multiple .select-search-input {
    cursor: initial;
}

.select-search--multiple .select-search-input {
    border-radius: 3px 3px 0 0;
}

.select-search--multiple:not(.select-search--search) .select-search-input {
    cursor: default;
}

.select-search-container:not(.select-search--multiple) .select-search-input:hover {
    border-color: #2FCC8B;
}


.select-search-container:not(.select-search--multiple) .select-search-select {
    position: absolute;
    z-index: 2;
    top: 44px;
    right: 0;
    left: 0;
    border-radius: 3px;
    overflow: auto;
    max-height: 360px;
}

.select-search--multiple .select-search-select {
    position: relative;
    overflow: auto;
    max-height: 260px;
    border-top: 1px solid #eee;
    border-radius: 0 0 3px 3px;
}

.select-search-not-found {
    height: auto;
    padding: 16px;
    text-align: center;
    color: #888;
}
.loadingIcon {
  animation: loadingIcon 1.2s linear infinite;
}

@keyframes loadingIcon {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}