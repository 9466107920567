/*.editresponsive{
	width: 10px;
}*/
::-webkit-scrollbar {
  width: 10px;
  height: 7px;
}
::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 10px;
}

::-moz-scrollbar {
  width: 10px;
  height: 7px;
}
::-moz-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 10px;
}

::-ms-scrollbar {
  width: 10px;
  height: 7px;
}
::-ms-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 10px;
}

::-o-scrollbar {
  width: 10px;
  height: 7px;
}
::-o-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 10px;
}