
@media only screen and (max-width: 991px) {
	.clickBot {
	 position: fixed;
	/* bottom: 15px;
	 top: 30%;
	 right: 15px;*/
     bottom: 145px;
	 height: 10px;
	 right: 2%;
	 transition: all .5s ease-in-out;
	 z-index: 99999999;
	}
}
@media only screen and (min-width: 992px) {
	.clickBot {
	 position: fixed;
	
     bottom: 145px;
	 right: 2%;
     
     height: 10px;
	 /*
	 top: 20%;
	 left: 2%;
     */
	 transition: all .5s ease-in-out;
	 z-index: 99999999;
	}
}
.setMiniContents{
	/*z-index: 99999999;*/
}
/*.cssContentMini {
	width: 30px;
	height: 30px;
	border-radius: 100px;
	border: solid 1px black;
	background-color: white;
	position: absolute;

}*/
	/*
	right: 100px;
	z-index: 99999999;
	*/
/*.cssContentMiniAnimation {
    -webkit-animation: cssContentMiniAnimation 2s infinite;
    animation: cssContentMiniAnimation 2s infinite;
}

@keyframes cssContentMiniAnimation {
            0% {
                transform: scale(1);
            }

            50% {
                transform: scale(1.5);
            }

            100% {
                transform: scale(1);
            }
}*/
/*@keyframes cssContentMiniAnimation {
  0%, 20%, 50%, 80%, 100% {
    -webkit-transform: translateY(0); }
  40% {
    -webkit-transform: translateY(-30px); }
  60% {
    -webkit-transform: translateY(-15px); } 
}*/

/*@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); }
  40% {
    -webkit-transform: translateY(-30px);
    -moz-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    -o-transform: translateY(-30px);
    transform: translateY(-30px); }
  60% {
    -webkit-transform: translateY(-15px);
    -moz-transform: translateY(-15px);
    -ms-transform: translateY(-15px);
    -o-transform: translateY(-15px);
    transform: translateY(-15px); } 
}*/