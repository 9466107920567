.ilink{
	filter: brightness(0) saturate(100%) invert(25%) sepia(69%) saturate(7412%) hue-rotate(200deg) brightness(95%) contrast(92%);
}
.iwhat{
	filter: brightness(0) saturate(100%) invert(56%) sepia(87%) saturate(414%) hue-rotate(90deg) brightness(100%) contrast(90%);
}
.iins{
	filter: brightness(0) saturate(100%) invert(21%) sepia(96%) saturate(6670%) hue-rotate(321deg) brightness(98%) contrast(103%);
	/*filter: brightness(0) saturate(100%) invert(25%) sepia(100%) saturate(4981%) hue-rotate(298deg) brightness(99%) contrast(126%);*/
}
.ifac{
	filter: brightness(0) saturate(100%) invert(51%) sepia(20%) saturate(6366%) hue-rotate(179deg) brightness(104%) contrast(98%);
}
.iyou{
	filter: brightness(0) saturate(100%) invert(12%) sepia(78%) saturate(6908%) hue-rotate(0deg) brightness(116%) contrast(113%);
}